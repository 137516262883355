.mod-pages {
    margin: 0 200px;
    padding: 20px 0;
    color: white;
}

.games {
    display: flex;
    justify-content: center;
}

.horizontal-scroll {
    overflow: auto;
    display: inline-flex;
    flex-direction: row;
    scroll-snap-type: x mandatory;
}

.game-column {
    display: flex;
    flex-direction: column;
    margin: 5px 9.5px;
    transition: 0.1s;
    scroll-snap-align: center;
}

.game {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    border: 3px solid white;
    transition: 0.1s;
}
.game-back {
    display: block;
    width: 330px;
    transition: 0.1s;
}

.game-logo {
    position: absolute;
    width: 330px;
    inset: 0 0 0 0;
}

.RPCS3-prop {
    position: absolute;
    width: 330px;
    inset: 0 0 0 0;
    filter: brightness(70%);
}

@keyframes RPCS3-anim {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(6px) scale(98%);
    }
    100% {
        transform: translateY(0);
    }
}

.JoJoAPI-prop {
    position: absolute;
    width: 330px;
    inset: 0 0 0 0;
    filter: brightness(70%);
}

@keyframes JoJoAPI-anim {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateX(45px) translateY(24px);
    }
    45% {
        transform: translateX(150px) translateY(5px);
    }
    80% {
        transform: translateX(15px) translateY(40px);
    }
    100% {
        transform: translate(0);
    }
}

.AllStarBattleR-prop {
    position: absolute;
    width: 330px;
    inset: 0 0 0 0;
    filter: brightness(70%);
}

@keyframes AllStarBattleR-anim {
    0% {
        transform: translateX(0) translateY(0);
    }
    25% {
        transform: translateX(-2px) translateY(2px) rotate(1deg);
    }
    50% {
        transform: translateX(2px) translateY(0px);
    }
    75% {
        transform: translateX(-2px) translateY(-2px);
    }
    100% {
        transform: translateX(0) translateY(0);
    }
}

.EyesOfHeaven-prop {
    position: absolute;
    width: 330px;
    inset: 0 0 0 0;
    filter: brightness(70%);
    opacity: 30%;
}

@keyframes EyesOfHeaven-anim {
    0% {
        opacity: 30%;
    }
    49% {
        opacity: 80%;
    }
    51% {
        opacity: 80%;
    }
    100% {
        opacity: 30%;
    }
}

.AllStarBattle-prop {
    position: absolute;
    width: 330px;
    inset: 0 0 0 0;
    filter: brightness(70%);
    opacity: 100%;
}

@keyframes AllStarBattle-anim {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 40%;
    }
    100% {
        opacity: 100%;
    }
}

/* PC ONLY */
@media only screen and (min-width: 768px) {
    .game-column {
        filter: blur(3px) brightness(30%);
    }
    .game-column:hover {
        filter: blur(0) brightness(100%);
    }

    .game-back {
        filter: brightness(70%) drop-shadow(0 10px 0.75rem black);
    }

    .horizontal-scroll:not(:hover) .game-column {
        filter: blur(0) brightness(100%);
    }
    .game:hover {
        transform: scale(105%);
        filter: grayscale(0%);
    }
    .game:hover .game-back {
        transform: scale(110%);
        filter: brightness(130%) drop-shadow(0 10px 0.75rem black);
    }
    
    .game:hover .RPCS3-prop {
        filter: brightness(100%);
        animation: RPCS3-anim 2s ease-in-out infinite;
    }
    .game:hover .JoJoAPI-prop {
        filter: brightness(100%);
        animation: JoJoAPI-anim 5s infinite;
    }
    .game:hover .AllStarBattleR-prop {
        filter: brightness(100%);
        animation: AllStarBattleR-anim 0.5s ease-in-out infinite;
    }
    .game:hover .EyesOfHeaven-prop {
        filter: brightness(100%);
        animation: EyesOfHeaven-anim 3s ease-in-out infinite;
    }
    .game:hover .AllStarBattle-prop {
        filter: brightness(100%);
        animation: AllStarBattle-anim 3s ease-in-out infinite;
    }
}

:root {
    --mobile-games-size: 230px;
}
/* MOBILE ONLY */
@media only screen and (max-width: 768px) {
    .mod-pages {
        margin: 10px;
    }

    .game-back {
        width: var(--mobile-games-size);
    }
    .game-logo {
        position: absolute;
        width: var(--mobile-games-size);
        inset: 0 0 0 0;
    }

    .RPCS3-prop {
        animation: RPCS3-anim 2s ease-in-out infinite;
        width: var(--mobile-games-size);
    }
    .JoJoAPI-prop {
        animation: JoJoAPI-anim 5s infinite;
        width: var(--mobile-games-size);
    }
    .AllStarBattleR-prop {
        animation: AllStarBattleR-anim 0.5s ease-in-out infinite;
        width: var(--mobile-games-size);
    }
    .EyesOfHeaven-prop {
        animation: EyesOfHeaven-anim 3s ease-in-out infinite;
        width: var(--mobile-games-size);
    }
    .AllStarBattle-prop {
        animation: AllStarBattle-anim 3s ease-in-out infinite;
        width: var(--mobile-games-size);
    }
}