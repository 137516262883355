.icons {
    position: absolute;
    top: 80%;
    width: 95%;
    margin: 0 2.5%;
    height: 12%;
    z-index: 1;
    display: flex;
    justify-content: center;
    gap: 3%;
}

.icon {
    width: 20%;
    height: 100%;
    border: 5px solid white;
    border-radius: 30px;
    will-change: filter;
    filter: drop-shadow(0 10px 0.75rem black);
    transition: 0.1s;
    overflow: hidden;
}
.icon a {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.icon img {
    height: 100%;
}
.icon p {
    margin-right: 5%;
    color: white;
    font-size: 4.0vh;
    font-family: "Helsinki", "Gen Jyuu", arial;
}

:root {
    --news-height: 400px;
}

.news-section {
    position: relative;
    display: flex;
    height: 100%;
}

.scrollbox {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    display: inline-flex;
    align-items: center;
}

.news-container {
    height: calc(var(--news-height) + 40px);
    display: inline-flex;
    align-items: center;
    padding: 0 50%;
    gap: 100px;
    flex-direction: row;
    position: relative;
    top: -10px;
}

.news-post, .news-post a {
    display: flex;
    position: relative;
    scroll-snap-align: center;
    transition: 0.1s;
    user-select: none;
    will-change: filter;
    filter: drop-shadow(0 10px 0.75rem rgba(0, 0, 0, 0.489));
}

.news-media {
    height: var(--news-height);
    width: calc(var(--news-height) * 1920/1080);
    border: 5px solid black;
    filter: brightness(100%);
    transition: 0.1s;
    background: grey;
}

.news-date {
    position: absolute;
    margin: 0;
    bottom: 75px;
    padding: 0 20px;
    color: yellow;
    font-family: "Courier Prime", monospace;
    font-size: 25px;
    text-align: left;
    transition: 0.2s;
}

.news-desc {
    position: absolute;
    margin: 0;
    bottom: 14px;
    padding: 0 20px;
    color: white;
    font-family: "Komika", "Digi Kyokasho", sans-serif;
    font-size: 25px;
    text-align: left;
    transition: 0.2s;
}

.news-back {
    position: absolute;
    margin: 0;
    bottom: -1px;
    width: calc(var(--news-height) * 1920/1080 + 10px);
    height: 115px;
    background-color: black;
    opacity: 75%;
    transition: 0.2s;
}

.see-more {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    transition: 0.1s;
    scroll-snap-align: center;
    user-select: none;
}
.see-more a {
    height: 50%;
}
.see-more img {
    height: 100%;
}
.see-more-1, .see-more-2 {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;

    font-family: "Helsinki", "Gen Jyuu", arial;
    font-size: 350%;
    color: white;
    filter: drop-shadow(0 5px 0.25rem black);
}
.see-more-1 {
    top: -50px;
}
.see-more-2 {
    bottom: -50px;
}

/* DESKTOP ONLY */
@media only screen and (min-width: 768px) {
    .icon:hover {
        transform: scale(1.1);
    }

    .news-post:hover {
        transform: scale(105%);
    }
    .news-post:hover .news-media {
        filter: brightness(120%);
    }
    .news-post:hover .news-desc, .news-post:hover .news-back, .news-post:hover .news-date {
        opacity: 0%;
    }

    .see-more:hover {
        transform: scale(1.1);
        filter: brightness(1.1);
    }
}

/* MOBILE ONLY */
@media only screen and (max-width: 768px) {
    :root {
        --news-height: 150px;
    }

    .scrollbox {
        gap: 30px;
    }

    .news-container {
        top: 35px;
        height: calc(var(--news-height) + 130px);
    }

    .news-post {
        top: 10px;
        height: calc(var(--news-height) + 150px);
    }

    .news-date {
        top: 165px;
        font-size: 14px;
    }
    .news-desc {
        top: 185px;
        font-size: 14px;
    }
    .news-back {
        bottom: 35px;
        height: 150px;
        z-index: -1;
        border-radius: 10px;
    }

    .see-more a {
        height: 70%;
    }

    .icons {
        position: absolute;
        top: auto;
        bottom: 10px;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        z-index: 1;
    }
    .icon {
        aspect-ratio: 1 / 1;
        width: 18dvw;
        height: auto;
        border-radius: 25px;
        display: inline-flex;
        align-items: center;
    }
    .icon p {
        display: none;
    }
}