body, html {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: visible; /* Stop background animation from creating horizontal scroll. */
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
}

h1 {
  text-align: center;
  font-size: 60px;
  margin: 0;
  font-family: "Helsinki", "Gen Jyuu", sans-serif;
  color: white;
  user-select: none;
}

p {
  text-align: left;
  font-size: 22px;
  font-family: "Komika", sans-serif;
}

/* BACKGROUND */

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-373px * 3), 0, 0); /* The image width */
  }
}

.bg-container {
  position: relative;
  overflow: hidden;
  height: 100dvh;
  width: 100%;
}

.bg {
  position: absolute;
  z-index: -1;
  background: linear-gradient(to bottom, rgba(238, 255, 1, 0.02), rgba(11, 25, 41, 0.73)), url("../assets/images/backgrounds/Background.png") repeat;
  background-size: 150px;
  height: 100%;
  width: 5076px;
  animation: slide 60s linear infinite;
}

/* LOGO */

.jjbmc-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 350px;
  z-index: 1;
  user-select: none;
}
.jjbmc-logo img {
  width: 350px;
}

.wonder-of-u {
  position: absolute;
  top: 25px;
  left: 200px;
  height: 150px;
  user-select: none;
}
.wonder-of-u img {
  height: 100%;
}

/* NAVBAR */

.navbar {
  position: absolute;
  top: 0;
  display: flex;
  height: 9%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
.nav-buttons {
  display: inline-flex;
  height: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: 80px;

  font-family: "Helsinki", "Gen Jyuu", sans-serif;
  font-size: 30px;
  letter-spacing: 5px;
  user-select: none;
}
.nav-buttons a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.nav-pipe {
  margin: 0 30px;
  font-family: "Starz";
  color: rgba(255, 255, 255, 0.159);
}

/* LANGUAGE SELECT */

.flag {
  position: relative;
  height: 60%;
  width: fit-content;
}
.flag img {
  height: 100%;
}
.flag-overflow {
  border: solid 2px white;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
}
.flag:hover img {
  filter: brightness(1.1);
}
.flag:hover .language-hitbox {
  display: block;
}
.language-hitbox {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 350%;
  height: 80px;
  /* border: solid 1px white; */
  display: none;
}
.flag:hover .language-dropdown {
  display: flex;
}
.language-dropdown {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 135%;
  transform: translateX(-55%);
  width: 380%;
  height: auto;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border: solid 3px white;
  display: none;
  flex-direction: column;

  font-family: "Komika", "Digi Kyokasho", arial;
  font-size: 30px;
  letter-spacing: 0;
}
.language-dropdown a {
  margin-top: 10px;
  margin-left: 15px;
}
.small-flag img {
  position: relative;
  top: 6px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
}

/* OTHER */

.markdown a {
  color: white;
}

.border {
  background-color: white;
  height: 5px;
}

.underneath {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(80, 80, 80, 0.5)), url("../assets/images/backgrounds/Background2.png") repeat;
  background-size: 100px;
  padding-bottom: 40px;
}

.about-us {
  width: 70%;
  margin: auto;
  margin-bottom: 30px;
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
}
.about-us-content {
  padding: 30px 50px;
}
.about-us p {
  padding-top: 10px;
}

.footer {
  position: fixed;
  bottom: -1px;
  width: 100vw;
  background-color: black;
  height: 30px;
  text-align: center;
  padding-top: 10px;
  font-family: "Komika", "Digi Kyokasho", sans-serif;
  text-transform: uppercase;
  transition: transform 0.5s ease, opacity 0.5s ease;
  user-select: none;
}
.footer a {
  color: white;
}
.footer.hidden {
  transform: translateY(10px);
  opacity: 0%;
  pointer-events: none;
}
.footer.visible {
  transform: translateY(0);
  opacity: 100%;
}

.banner {
  display: block;
  width: 90%;
  margin: 15px auto;
  border: dotted 3px white;
  border-radius: 15px;
}

/* MOBILE */

@media only screen and (max-width: 768px) {
  .bg-container {
    height: 660px;
  }

  .jjbmc-logo {
    position: absolute;
    top: 65px;
    left: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    pointer-events: none;
  }
  .jjbmc-logo img {
    width: 300px;
  }

  .no-mobile {
    display: none;
  }

  .navbar {
    z-index: 2;
  }
  .nav-buttons {
    align-items: center;
    margin: auto;
    font-size: 25px;
    letter-spacing: 0px;
  }
  .nav-pipe {
    margin: 0 10px;
  }

  .language-dropdown {
    width: 350%;
    left: -20%;
    height: auto;
    font-size: 25px;
  }
  .small-flag img {
    top: 5px;
    height: 25px;
  }

  .about-us {
    width: 100%;
  }
  .about-us-content {
    padding: 30px 30px;
  }
  .banner {
    width: 100%;
    margin: 15px auto;
  }
}

/* DESKTOP */

@keyframes wonder-of-u-anim {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
    filter: grayscale(0);
  }
  100% {
    opacity: 0.1;
    filter: grayscale(1);
  }
}

@media only screen and (min-width: 768px) {
  .only-mobile {
    display: none;
  }

  h1:before, h1:after {
    font-family: "Starz";
    content: "F";
    margin: 0 20px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.2);
    position: relative;
    bottom: 7px;
  }

  .nav-buttons a:hover {
    color: yellow;
  }

  .jjbmc-logo:hover {
    animation: wonder-of-u-anim 5s forwards;
  }
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1d1d1d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7c7c7c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}